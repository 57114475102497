import React from 'react';
import { FormLayout, TextField } from "@shopify/polaris";

export default (props) => {

    const { rate, formErrors } = props;

    const handleChange = (key, value) => {
        props.handleChange(key, value);
    }

    const ucFirstType = () => {
        switch (rate.type) {
            case 'item_count':
                return 'Item Count';
            case 'subtotal':
                return 'Subtotal';
            case 'weight':
            default:
                return 'Weight';
        }
    }

    return (
        <div>
            <FormLayout>
                <TextField label="Rate Name"
                           placeholder="Metro"
                           onChange={(value) => handleChange('name', value)}
                           value={rate.name}
                           error={formErrors?.name && formErrors.name.length > 0 && formErrors.name[0]}
                />

                <TextField label="Description"
                           placeholder="A description that your customers will see"
                           onChange={(value) => handleChange('description', value)}
                           value={rate.description}
                           multiline={4}
                           error={formErrors?.description && formErrors.description.length > 0 && formErrors.description[0]}
                />

                <TextField label={`Minimum ${ucFirstType()}`}
                           prefix={rate.type === 'subtotal' ? '$' : null}
                             suffix={rate.type === 'weight' ? 'grams' : null}
                           placeholder="0"
                           onChange={(value) => handleChange('minimum', value)}
                           value={rate.minimum}
                           error={formErrors?.minimum && formErrors.minimum.length > 0 && formErrors.minimum[0]}
                />

                <TextField label={`Maximum ${ucFirstType()}`}
                           prefix={rate.type === 'subtotal' ? '$' : null}
                            suffix={rate.type === 'weight' ? 'grams' : null}
                           placeholder="Leave blank for no maximum"
                           onChange={(value) => handleChange('maximum', value)}
                           value={rate.maximum}
                           error={formErrors?.maximum && formErrors.maximum.length > 0 && formErrors.maximum[0]}
                />

                <TextField label={`Amount`}
                           prefix="$"
                           placeholder=""
                           type="text"
                           onChange={(value) => handleChange('amount', value)}
                           value={rate.amount}
                           error={formErrors?.amount && formErrors.amount.length > 0 && formErrors.amount[0]}
                />

            </FormLayout>
        </div>
    )
};
