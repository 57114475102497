import React, { useCallback, useState } from "react";
import { Modal, TextContainer } from "@shopify/polaris";


export default function FailedToCreateCarrierService(props) {
    const { message } = props;

    const [active, setActive] = useState(true);
    const toggleModal = useCallback(() => setActive((active) => !active), []);

    return (
        <div style={{height: '500px'}}>
            <Modal
                open={active}
                onClose={toggleModal}
                title="Unable to Create Carrier Service"
                primaryAction={{
                    content: 'Close',
                    onAction: toggleModal,
                }}
            >
                <Modal.Section>
                    <TextContainer>
                        <p>
                            We were unable to create a carrier service Please verify your
                            Shopify plan has access to the carrier service.
                        </p>
                        <p>
                            {message || ''}
                        </p>
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </div>
    );
}
