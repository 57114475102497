import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import http from '../../utilities/http';
import { connect, useSelector } from 'react-redux';
import {
    Page,
    Frame,
    Layout, Card, SkeletonPage, SkeletonBodyText, Heading, TextStyle, Subheading, ResourceList, ResourceItem
} from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import format from 'date-fns/format';
import { currency } from "../../utilities/currency";
import { setDateTimeAsUTC } from "../../utilities/time";


function Show() {
    const site = useSelector(state => state.site);
    const [isLoading, setIsLoading] = useState(true);
    const [log, setLog] = useState({});
    let { id } = useParams();

    useEffect(() => {

        http.get(`/rate-logs/${id}`).then(function(response) {
            setLog(response.data);
            setIsLoading(false);
        }).catch(err => {
        })
    }, [id]);

    let rates = [];
    try {
        rates = log.response && log.response !== "" && JSON.parse(log.response);
    } catch(e) {
    }

    const dateFormat = site?.site?.dateFormat ?? "dd/MM/yy";

    if (isLoading) return <SkeletonPage>
            <Layout>
                <Layout.Section>
                    <Card sectioned>
                        <SkeletonBodyText />
                    </Card>
                </Layout.Section>
            </Layout>
        </SkeletonPage>;

    return (
        <Page
            title={`Viewing Log for ${log.postcode}`}
            breadcrumbs={[
                {
                    content: 'Logs',
                    url: '/logs'
                }
            ]}
        >
            <TitleBar title={`Viewing Log for ${log.postcode}`} />
            <Frame>
                <Layout>
                    <Layout.AnnotatedSection
                        title="Log Details"
                        description="The details we log about the checkout">

                        <Card sectioned>
                            <Heading element="h1">Postcode: {log.postcode}</Heading>
                            <TextStyle variation="subdued">
                                Date: {format(new Date(setDateTimeAsUTC(log.created_at)), `${dateFormat} h:mm a`)}
                            </TextStyle>
                            <br /><br /><br />

                            <Subheading>Order Details</Subheading>
                            <TextStyle variation="strong">Order Weight:</TextStyle> {log.weight} grams
                            <br />
                            <TextStyle variation="strong">Total Items In Order:</TextStyle> {log.item_count}
                            <br />
                            <TextStyle variation="strong">Order Subtotal:</TextStyle> ${currency(log.subtotal)}
                        </Card>

                    </Layout.AnnotatedSection>
                </Layout>
                <br />
                <Layout>
                    <Layout.AnnotatedSection
                        title="Rates"
                        description="The rates we responded with">

                        <Card sectioned>
                            <Subheading>{log.total_results} Result{(log.total_results === 1 ? '' : 's')}</Subheading>
                            {rates.length > 0 ? (
                                <ResourceList
                                    resourceName={{singular: 'rate', plural: 'rates'}}
                                    totalItemsCount={rates.length}
                                    items={rates}
                                    renderItem={(item, index) => {
                                    return <ResourceItem
                                        id={index}>
                                        <h2>{item.service_name}</h2>
                                        <div>
                                            <TextStyle variation="subdued">{item.description}</TextStyle>
                                        </div>
                                        <TextStyle variation="subdued">${currency(item.total_price)}</TextStyle>
                                    </ResourceItem>;
                                }} />
                            ) : (
                                <div>No rates found.</div>
                            )}
                        </Card>
                    </Layout.AnnotatedSection>
                </Layout>
            </Frame>
        </Page>
    )

}

export default connect(null, null)(Show);
