import axios from 'axios';
import appConfig from "../config";
import { getSessionToken } from "@shopify/app-bridge-utils";

const instance = axios.create();
instance.defaults.baseURL = `${appConfig.API_URL}`;
instance.interceptors.request.use(function(config) {
    // const token = window.sessionStorage.getItem('jwt');
    if (appConfig.PRODUCTION_MODE === false && !window.Shopify) {
        const token = window.sessionStorage.getItem('jwt');
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    }

    return getSessionToken(window.shopifyApp).then((token) => {
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    }).catch(error => {
        console.log('token error', error);
        return config;
    });
});

export default instance;
