import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import List from './List';
import Show from './Show';

function Logs() {
    let { url } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${url}/:id`} component={Show} />
                <Route path={`${url}/`} component={List} />
            </Switch>
        </div>
    );
}

export default Logs;
