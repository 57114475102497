import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Link } from 'react-router-dom'
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react'
import enTranslations from '@shopify/polaris/locales/en.json';
import {AppProvider as PolarisProvider} from "@shopify/polaris";
import * as serviceWorker from './serviceWorker';
import config from './config';
import AppRouter from './AppRouter';
import { createStore, applyMiddleware } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import thunk from 'redux-thunk';
import '@shopify/polaris/dist/styles.css';

import rootReducer from './store/reducers/rootReducer';
const store = createStore(rootReducer, applyMiddleware(thunk));

window.shopifyApp = {};

const paramsSearcher = new URLSearchParams(window.location.search)
const findOrigin = paramsSearcher.get('shop') || '';

if (findOrigin === '') {
    window.location.href = 'https://ratesbypostcode.com/';
} else if (paramsSearcher.get('hmac') && paramsSearcher.get('shop') && !paramsSearcher.get('session')) {
    window.location.href = `${config.API_URL}/oauth${window.location.search}`;
} else {

    const shopifyConfig = {
        apiKey: config.API_KEY,
        shopOrigin: findOrigin,
        host: paramsSearcher.get('host') || '',
        forceRedirect: true
    }

    const CustomLinkComponent = ({children, url, ...rest}) => {
        if (url.substr(0, 4) === 'http') {
            return (
                <a href={url} rel="noopener noreferrer" target="_blank" {...rest}>{children}</a>
            )
        }

        return (
            <Link to={url} {...rest}>{children}</Link>
        );
    };


    ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <AppBridgeProvider config={shopifyConfig}>
                    <ReduxProvider store={store}>
                        <PolarisProvider i18n={enTranslations} linkComponent={CustomLinkComponent}>
                            <AppRouter/>
                            <App/>
                        </PolarisProvider>
                    </ReduxProvider>
                </AppBridgeProvider>
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
