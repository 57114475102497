import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Card,
    Layout,
    Page, Spinner,
} from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import http from '../../utilities/http';

function Index() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        if (isLoading) {
            http.post('/shopify/charge', {}).then(({data}) => {
                if (data.confirmation_url) {
                    window.top.location.href = data.confirmation_url;
                }

            }).catch(error => {
                console.log('err', error);
                setIsLoading(false);
            });
        }
    }, [isLoading])


    return (
        <Page>
            <TitleBar title="Subscribe" />
            <Page narrowWidth singleColumn title="">
                <Layout>
                    <Layout.Section>{/* Page-level banners */}</Layout.Section>
                    <Layout.Section>
                        <Card sectioned>
                            <div style={{textAlign: 'center'}}>
                                <Spinner accessibilityLabel="Loading subscription" size="large" color="teal" />
                            </div>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>

        </Page>
    )

}

export default connect(null, null)(Index);
