import React, { useEffect, useState } from 'react';
import http from '../../../utilities/http';
import { connect } from 'react-redux';
import {
    Form,
    Page,
    Frame,
    Toast,
    PageActions,
    Layout, Card
} from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery} from "../../../utilities/query";
import RateForm from "./components/RateForm";

function Create() {

    let query = useQuery();
    const type = query.get('type') ?? 'weight';
    const [isSaving, setIsSaving] = useState(false);
    const [newRate, setNewRate] = useState({
        name: '',
        description: '',
        type: type,
        minimum: '0',
        maximum: '',
        amount: '0'
    });
    const [changeCounter ,setChangeCounter] = useState(0);
    const [showFeedback, setShowFeedback] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    let history = useHistory();
    let { zoneId } = useParams();

    useEffect(() => {
    }, []);

    useEffect(() => {}, [newRate]);

    const handleSubmit = () => {
        let data = {...newRate};
        delete data.id;

        if (data.maximum === "") {
            delete data.maximum;
        } else {
            data.maximum = parseInt(data.maximum);
        }

        data.minimum = parseInt(data.minimum);
        data.amount = parseFloat(data.amount);

        setIsSaving(true);

        http.post(`/zones/${zoneId}/rates`, data).then(response => {
            history.push(`/zones/${zoneId}/rates/${response.data.id}/edit`);
        }).catch(error => {
            setIsSaving(false);
            if (error.response.status === 422) {
                setFormErrors(error.response.data.errors);
            }
        });
    };

    const handleChange = (field, newValue) => {
        let formZone = newRate;
        formZone[field] = newValue;
        setNewRate(formZone);
        setChangeCounter(changeCounter+1);
    }

    const toastMarkup = showFeedback ? (
        <Toast content="Rate saved" duration={3000} onDismiss={setShowFeedback} />
    ) : null;

    return (
        <Page
            title={`Create Rate`}
            breadcrumbs={[
                {
                    content: 'Zones',
                    url: `/zones/`
                },
                {
                    content: 'Back to Zone',
                    url: `/zones/${zoneId}/edit`
                }
            ]}
        >
            <TitleBar title={`Create Rate`} />
            <Frame>
                <Form onSubmit={handleSubmit}>
                    <Layout>
                        <Layout.AnnotatedSection
                            title="Rate Details"
                            description="General rate information"
                        >
                            <Card sectioned>
                                <RateForm rate={newRate} formErrors={formErrors} handleChange={handleChange} />
                            </Card>
                        </Layout.AnnotatedSection>
                    </Layout>
                </Form>
                <br />

                <PageActions
                    primaryAction={{
                        content: 'Save',
                        loading: isSaving,
                        onAction() {
                            handleSubmit();
                        }
                    }}
                />
                {toastMarkup}
            </Frame>
        </Page>
    )

}

export default connect(null, null)(Create);
