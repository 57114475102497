import authReducer from './authReducer';
import siteReducer from "./siteReducer";
import zoneReducer from "./zoneReducer";
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    auth: authReducer,
    site: siteReducer,
    zone: zoneReducer
});

export default rootReducer;
