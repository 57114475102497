import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
    Button,
    CalloutCard,
    Card,
    DisplayText,
    Heading,
    Layout,
    Page,
    SettingToggle,
    TextStyle
} from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import http from '../../utilities/http';
import {useSelector} from "react-redux";
import { setCarrierServiceIsActive } from "../../store/actions/siteActions";
import FailedToCreateCarrierService from "../FailedToCreateCarrierService";
import CarrierServiceRequired from "./CarrierServiceRequired";

function Dashboard() {
    const site = useSelector(state => state.site);
    const dispatch = useDispatch();
    const [isChangingStatus, setIsChangingStatus] = useState(false);
    const [showFailedCarrierService, setShowFailedCarrierService] = useState(null);

    const contentStatus = site.site.installed ? 'Disable' : 'Enable';
    const textStatus = site.site.installed ? 'enabled' : 'disabled';

    const handleToggle = () => {
        setIsChangingStatus(true);
        setShowFailedCarrierService(null);

        let method = site.site.installed ? 'put' : 'post';
        let url = site.site.installed ? '/carrier-service/status' : '/carrier-service';
        let postData = {
            active: !site.site.installed
        };

        http[method](url, postData).then(({data}) => {
            dispatch(setCarrierServiceIsActive(data.active));
        }).catch(error => {
            setShowFailedCarrierService(error.response.data);
        }).finally(() => {
            setIsChangingStatus(false);
        });
    }

    useEffect(() => {}, [site]);

    return (
        <Page>
            <TitleBar title="Dashboard" />
            <Layout>
                <Layout.Section>

                    {!site.site.hasAccessToCarrierService && <CarrierServiceRequired />}

                    {site.site.zoneCount === 0 && (<Card sectioned>
                        <Heading element="h1">
                            <DisplayText size="large">Welcome to Rates by Postcode</DisplayText>
                        </Heading>
                        <p>
                            Rates by Postcode allows you to set shipping rates based on partial or exact post codes.
                            Adjust rates based on weight, subtotal or even the number of items in the order.
                        </p>
                        <br />
                        <p>
                            Get started by <Button plain url={`/zones`}> creating your first zone</Button>.
                        </p>
                    </Card>)}

                    {site.site.hasAccessToCarrierService && (
                        <SettingToggle
                            action={{
                                content: contentStatus,
                                onAction: handleToggle,
                                loading: isChangingStatus
                            }}
                            enabled={site.site.installed}
                        >
                            Rates by Postcode is <TextStyle variation="strong">{textStatus}</TextStyle>.
                        </SettingToggle>
                    )}


                    <CalloutCard
                        title="Review your logs"
                        primaryAction={{
                            content: 'View logs',
                            url: '/logs',
                        }}
                    >
                        <p>When a request is made for shipping rates, we log the date, item count, weight and subtotal along with the rates we responded with for you to review.</p>
                        <p>You have access <TextStyle variation="strong">30</TextStyle> days of logs.</p>
                    </CalloutCard>
                </Layout.Section>
            </Layout>

            {showFailedCarrierService != null && <FailedToCreateCarrierService message={showFailedCarrierService} />}
        </Page>
    )

}

export default connect(null, null)(Dashboard);
