import React, { useEffect } from 'react';
import {Switch, Route} from 'react-router-dom';
import { useAppBridge, useRoutePropagation } from '@shopify/app-bridge-react';
import Zones from './components/zones';
import Settings from './components/settings';
import Dashboard from './components/dashboard';
import Logs from './components/logs';
import Plan from './components/plan';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from "./store/actions/authActions";
import http from "./utilities/http";
import { setCurrentSite } from "./store/actions/siteActions";
import { Frame, Loading } from "@shopify/polaris";
import { withRouter } from "react-router";

function App({ location }) {
    window.shopifyApp = useAppBridge();
    const site = useSelector(state => state.site);
    const dispatch = useDispatch();
    useRoutePropagation(location);

    useEffect(() => {
        http.get('/dashboard?load=1').then(({data}) => {
            dispatch(setCurrentSite(data));
        });
    }, [dispatch]);

    if (!site.isLoaded) {
        return (
            <div style={{height: '100px'}}>
                <Frame>
                    <Loading />
                </Frame>
            </div>
        )
    }

    // && !site.isDevelopmentStore
    if (!site.isSubscribed && !site.isDevelopmentStore) {
        return <Plan />
    }

    return (
        <Switch>
            <Route path="/zones" component={Zones} />
            <Route path="/settings" component={Settings} />
            <Route path="/logs" component={Logs} />
            <Route path="/auth/inline" exact component={Dashboard} />
            <Route path="/plan" exact component={Plan} />
            <Route path="/" exact component={Dashboard} />
        </Switch>
    );
}

const mapDispatchToProps = {
    setAuth: setCurrentUser
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
