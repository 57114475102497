// import agents from '../../api/agents';

export const setCurrentUser = (access_token) => {
    return (dispatch) => {

        return dispatch({
            type: 'AUTH_LOGIN_SUCCESS',
            access_token
        });
    }
}

export const logout = () => {
    return (dispatch) => {
        dispatch({
            type: 'AUTH_LOGOUT'
        });
    }
}
