import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import http from '../../utilities/http';
import { connect, useSelector } from 'react-redux';
import {
    Form,
    FormLayout,
    Page,
    Frame,
    Toast,
    PageActions,
    TextContainer,
    Modal,
    Checkbox, Layout, Card, Button, ResourceList, ResourceItem, SkeletonPage, SkeletonBodyText, TextStyle
} from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import Rate from "./rates/components/Rate";
import { TextField } from '../shopify/TextField/TextField';

function Edit() {
    const site = useSelector(state => state.site);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [zone, setZone] = useState({});
    const [newZone, setNewZone] = useState({});
    const [changeCounter ,setChangeCounter] = useState(0);
    const [showFeedback, setShowFeedback] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    let history = useHistory();
    let { id } = useParams();

    useEffect(() => {

        http.get(`/zones/${id}`).then(function(response) {
            setIsLoading(false);
            setZone(response.data);
            setNewZone({...response.data});
        }).catch(err => {
            console.log('err', err);
        })
    }, [id]);

    useEffect(() => {}, [newZone]);

    const handleSubmit = () => {
        let data = newZone;
        delete data.id;

        setIsSaving(true);
        setFormErrors({});

        http.put(`/zones/${id}`, data).then(response => {
            setShowFeedback(true);
        }).catch(error => {
            if (error.response.status === 422) {
                setFormErrors(error.response.data.errors);
            }

        }).finally(() => {
            setIsSaving(false);
        });
    };

    const handleDelete = () => {
        setIsDeleting(true);
        http.delete(`/zones/${id}`).then(response => {
            history.replace('/zones');
        }).catch(error => {
            setIsDeleting(false);
        });
    }

    const handleChange = (field, newValue) => {
        let formZone = newZone;
        formZone[field] = newValue;
        setNewZone(formZone);
        setChangeCounter(changeCounter+1);
    }

    const toastMarkup = showFeedback ? (
        <Toast content="Zone saved" duration={3000} onDismiss={setShowFeedback} />
    ) : null;

    const weightRates = (zone.rates && zone.rates.filter(item => item.type === "weight")) || [];
    const subtotalRates = (zone.rates && zone.rates.filter(item => item.type === "subtotal")) || [];
    const itemCountRates = (zone.rates && zone.rates.filter(item => item.type === "item_count")) || [];

    if (isLoading) return <SkeletonPage title="Edit Zone"
                                        breadcrumbs={[
                                            {
                                                content: 'Zones',
                                                url: '/zones'
                                            }
        ]}>
        <Layout>
            <Layout.AnnotatedSection
                title="Zone Details"
                description="General zone information"
            >
                <Card sectioned>
                    <SkeletonBodyText />
                </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
                title="Rates"
                description="Your different rates"
            >
                <Card sectioned>
                    <SkeletonBodyText />
                </Card>
            </Layout.AnnotatedSection>
        </Layout>
    </SkeletonPage>;

    return (
        <Page
            title={`Edit ${zone.name}`}
            breadcrumbs={[
                {
                    content: 'Zones',
                    url: '/zones'
                }
            ]}
        >
            <TitleBar title={`Edit ${zone.name}`} />
            <Frame>
                <Form onSubmit={handleSubmit}>
                    <Layout>
                        <Layout.AnnotatedSection
                            title="Zone Details"
                            description="General zone information"
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <TextField
                                        label="Zone Name"
                                        placeholder="Metro"
                                        onChange={(value) => handleChange('name', value)} value={newZone.name}
                                        error={formErrors?.name && formErrors.name.length > 0 && formErrors.name[0]}
                                    />

                                    {!newZone.is_fallback && <TextField
                                        label="Post Codes"
                                        placeholder="3000, 3200, 3203"
                                        helpText="Comma separated or separated by a new line.  Duplicate post codes are automatically removed when saving."
                                        onChange={(value) => handleChange('postcodes', value)}
                                        value={newZone.postcodes}
                                        multiline={4}
                                        error={formErrors?.postcodes && formErrors.postcodes.length > 0 && formErrors.postcodes[0]}
                                    />}

                                    {!newZone.is_fallback && (
                                        <div>
                                            <TextStyle variation="subdued">Postcode matching is currently set to <strong>{site.site.postcodeMatching}</strong> matching. Visit the <Button plain url={`/settings`}>settings</Button> page to change.</TextStyle>
                                            <br /><br />
                                        </div>
                                    )}

                                    <Checkbox
                                        label="Zone is active"
                                        checked={newZone.is_active}
                                        onChange={(value) => handleChange('is_active', value)}
                                        error={formErrors?.is_active && formErrors.is_active.length > 0 && formErrors.is_active[0]}
                                    />

                                    <Checkbox
                                        label="Zone is a fallback"
                                        checked={newZone.is_fallback}
                                        onChange={(value) => handleChange('is_fallback', value)}
                                        error={formErrors?.is_fallback && formErrors.is_fallback.length > 0 && formErrors.is_fallback[0]}
                                        helpText="When a postcode does not match any zones, use rates from this zone. A fallback zone allows you to provide rates to customers outside your delivery area if you want."
                                    />

                                    <div style={{
                                        textAlign: 'right'
                                    }}>
                                        <Button primary
                                                loading={isSaving}
                                                onClick={() => {
                                                    handleSubmit()
                                                }}
                                        >Save</Button>
                                    </div>

                                </FormLayout>
                            </Card>
                        </Layout.AnnotatedSection>

                        <Layout.AnnotatedSection
                            title="Rates"
                            description="Your different rates"
                        >
                            <Card
                                sectioned
                                title="Rate by Weight"
                                actions={[{content: 'Add Rate', url: `/zones/${zone.id}/rates/create?type=weight`}]}
                            >
                                <ResourceList
                                    items={weightRates}
                                    renderItem={(item) => {
                                        return (
                                            <ResourceItem
                                                id={item.id}
                                                url={`/zones/${zone.id}/rates/${item.id}/edit`}
                                                name={item.name}
                                                accessibilityLabel={`View details for ${item.name}`}
                                            >
                                                <Rate rate={item} />
                                            </ResourceItem>
                                        )
                                    }}
                                    resourceName={{singular: 'zone', plural: 'zones'}}
                                />
                                {weightRates.length === 0 && (
                                    <p>Add rates based on the order weight.</p>
                                )}
                            </Card>

                            <Card
                                sectioned
                                title="Rate by Subtotal"
                                actions={[{content: 'Add Rate', url: `/zones/${zone.id}/rates/create?type=subtotal`}]}
                            >
                                <ResourceList
                                    items={subtotalRates}
                                    renderItem={(item) => {
                                        return (
                                            <ResourceItem
                                                id={item.id}
                                                url={`/zones/${zone.id}/rates/${item.id}/edit`}
                                                name={item.name}
                                                accessibilityLabel={`View details for ${item.name}`}
                                            >
                                                <Rate rate={item} />
                                            </ResourceItem>
                                        )
                                    }}
                                    resourceName={{singular: 'zone', plural: 'zones'}}
                                />
                                {subtotalRates.length === 0 && (
                                    <p>Add rates based on the order subtotal.</p>
                                )}
                            </Card>

                            <Card
                                sectioned
                                title="Rate by Item Count"
                                actions={[{content: 'Add Rate', url: `/zones/${zone.id}/rates/create?type=item_count`}]}
                            >
                                <ResourceList
                                    items={itemCountRates}
                                    renderItem={(item) => {
                                        return (
                                            <ResourceItem
                                                id={item.id}
                                                url={`/zones/${zone.id}/rates/${item.id}/edit`}
                                                name={item.name}
                                                accessibilityLabel={`View details for ${item.name}`}
                                            >
                                                <Rate rate={item} />
                                            </ResourceItem>
                                        )
                                    }}
                                    resourceName={{singular: 'zone', plural: 'zones'}}
                                />
                                {itemCountRates.length === 0 && (
                                    <p>Add rates based on the number of items ordered.</p>
                                )}
                            </Card>
                        </Layout.AnnotatedSection>
                    </Layout>
                </Form>
                <br />
                <PageActions

                    secondaryActions={[
                        {
                            content: 'Delete zone',
                            destructive: true,
                            onAction() {
                                setDeleteModalOpen(true);
                            }
                        },
                    ]}
                />
                {toastMarkup}

                <Modal

                    open={deleteModalOpen}
                    onClose={handleChange}
                    title="Delete Zone?"
                    primaryAction={{
                        content: 'Delete Zone',
                        destructive: true,
                        loading: isDeleting,
                        onAction() {
                            handleDelete()
                        }
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction() {
                                setDeleteModalOpen(false);
                            },
                        },
                    ]}
                >
                    <Modal.Section>
                        <TextContainer>
                            <p>Are you sure you want to delete the zone <strong>{zone.name}</strong>? This can't be undone.</p>
                        </TextContainer>
                    </Modal.Section>
                </Modal>
            </Frame>
        </Page>
    )

}

export default connect(null, null)(Edit);
