import React, {useEffect, useState} from 'react';
import http from '../../utilities/http';
import { connect, useSelector } from 'react-redux';
import {
    Card,
    EmptyState, Filters,
    Page, Pagination,
    ResourceItem,
    ResourceList,
    Stack,
    TextStyle
} from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import format from 'date-fns/format';
import useDebounce from "../../utilities/useDebounce";
import { setDateTimeAsUTC } from "../../utilities/time";

function List() {

    const site = useSelector(state => state.site);
    const [queryValue, setQueryValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [logs, setLogs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalLogs, setTotalLogs] = useState(0);

    const searchQuery = useDebounce(queryValue, 500);

    useEffect(() => {
        setIsLoading(true);

        http.get('/rate-logs?page=' + currentPage + '&postcode=' + searchQuery).then(function(response) {
            setIsLoading(false);
            setLogs(response.data.items);
            setTotalPages(response.data.pager.totalPages);
            setTotalLogs(response.data.pager.total);
        }).catch(err => {
            console.log('err', err);
        })
    }, [searchQuery, currentPage]);

    const emptyStateMarkup = logs.length === 0 ? (
        <EmptyState
            heading="No logs found"
            image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
        >
            <p>We record the post code and the rates we respond with for you to verify.</p>
        </EmptyState>
    ) : undefined;

    const filterControl = (
        <Filters
            queryPlaceholder="Filter logs by postcode"
            queryValue={queryValue}
            onQueryChange={value => {
                setQueryValue(value);
                setCurrentPage(1);
            }}
            onQueryClear={() => {
                setQueryValue('');
                setCurrentPage(1);
            }}
            filters={[]}
        >

        </Filters>
    );

    const dateFormat = site?.site?.dateFormat ?? "dd/MM/yy";

    return (
        <Page title="Logs"
        >
            <TitleBar title="Logs" />
            <Card>
                <ResourceList
                    totalItemsCount={totalLogs}
                    resourceName={{singular: 'log', plural: 'logs'}}
                    showHeader={totalLogs > 0}
                    emptyState={emptyStateMarkup}
                    items={logs}
                    filterControl={filterControl}
                    loading={isLoading}
                    renderItem={(item) => {
                        return (
                            <ResourceItem
                                id={item.id}
                                url={`logs/${item.id}`}
                                name={item.postcode}
                                accessibilityLabel={`View details for ${item.postcode}`}
                                loading={isLoading}
                            >
                                <h3>
                                    <Stack>
                                        <Stack.Item fill>
                                            <TextStyle variation="strong">{item.postcode}</TextStyle>
                                        </Stack.Item>
                                        <Stack.Item>
                                            {format(new Date(setDateTimeAsUTC(item.created_at)), `${dateFormat} h:mm a`)}
                                        </Stack.Item>
                                    </Stack>

                                </h3>
                                <div>
                                    <TextStyle variation="subdued">{item.total_results} result(s)</TextStyle>
                                </div>

                            </ResourceItem>
                        )
                    }}

                />

                <br />

                {totalPages > 1 && (<div style={{textAlign: "center"}}>
                    <Pagination
                        hasPrevious={currentPage > 1}
                        onPrevious={() => {
                            if (currentPage > 1) {
                                setCurrentPage(currentPage - 1);
                            }

                        }}
                        hasNext={currentPage < totalPages}
                        onNext={() => {
                            setCurrentPage(currentPage + 1);
                        }}
                    />
                    <br /><br />
                </div>)}

            </Card>
        </Page>
    )

}

export default connect(null, null)(List);
