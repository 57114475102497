import React, { useEffect, useState } from 'react';
import http from '../../utilities/http';
import { connect, useSelector } from 'react-redux';
import {
    Form,
    FormLayout,
    Page,
    TextField,
    Frame,
    Toast,
    PageActions,
    Checkbox, Layout, Card, TextStyle, Button
} from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import { useHistory } from "react-router-dom";

function Create() {

    const site = useSelector(state => state.site);
    const [isSaving, setIsSaving] = useState(false);
    const [newZone, setNewZone] = useState({
        name: '',
        postcodes: '',
        is_active: true
    });
    const [changeCounter ,setChangeCounter] = useState(0);
    const [showFeedback, setShowFeedback] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    let history = useHistory();

    useEffect(() => {
    }, []);

    useEffect(() => {}, [newZone]);

    const handleSubmit = () => {
        let data = newZone;
        delete data.id;
        setIsSaving(true);

        http.post(`/zones`, data).then(response => {
            history.push(`/zones/${response.data.id}/edit`);
        }).catch(error => {
            setIsSaving(false);
            if (error.response.status === 422) {
                setFormErrors(error.response.data.errors);
            }
        });
    };

    const handleChange = (field, newValue) => {
        let formZone = newZone;
        formZone[field] = newValue;
        setNewZone(formZone);
        setChangeCounter(changeCounter+1);
    }

    const toastMarkup = showFeedback ? (
        <Toast content="Zone saved" duration={3000} onDismiss={setShowFeedback} />
    ) : null;

    return (
        <Page
            title={`Create Zone`}
            breadcrumbs={[
                {
                    content: 'Zones',
                    url: '/zones'
                }
            ]}
        >
            <TitleBar title={`Create Zones`} />
            <Frame>
                <Form onSubmit={handleSubmit}>
                    <Layout>
                        <Layout.AnnotatedSection
                            title="Zone Details"
                            description="General zone information"
                        >
                            <Card sectioned>
                                <FormLayout>
                                    <TextField label="Zone Name"
                                               placeholder="Metro"
                                               onChange={(value) => handleChange('name', value)}
                                               value={newZone.name}
                                               error={formErrors?.name && formErrors.name.length > 0 && formErrors.name[0]}
                                    />

                                    {!newZone.is_fallback && <TextField label="Post Codes"
                                               placeholder="3000, 3200, 3203"
                                               helpText={`Comma separated or separated by a new line.  Duplicate post codes are automatically removed when saving.
                                               `}
                                               onChange={(value) => handleChange('postcodes', value)}
                                               value={newZone.postcodes} multiline={4}
                                               error={formErrors?.postcodes && formErrors.postcodes.length > 0 && formErrors.postcodes[0]}
                                    />}

                                    {!newZone.is_fallback && (
                                        <div>
                                            <TextStyle variation="subdued">Postcode matching is currently set to <strong>{site.site.postcodeMatching}</strong> matching. Visit the <Button plain url={`/settings`}>settings</Button> page to change.</TextStyle>
                                            <br /><br />
                                        </div>
                                    )}

                                    <Checkbox
                                        label="Zone is active"
                                        checked={newZone.is_active}
                                        onChange={(value) => handleChange('is_active', value)}
                                        error={formErrors?.is_active && formErrors.is_active.length > 0 && formErrors.is_active[0]}
                                    />

                                    <Checkbox
                                        label="Zone is a fallback"
                                        checked={newZone.is_fallback}
                                        onChange={(value) => handleChange('is_fallback', value)}
                                        error={formErrors?.is_fallback && formErrors.is_fallback.length > 0 && formErrors.is_fallback[0]}
                                        helpText="When a postcode does not match any zones, use rates from this zone. A fallback zone allows you to provide rates to customers outside your delivery area if you want."
                                    />
                                </FormLayout>

                            </Card>
                        </Layout.AnnotatedSection>
                    </Layout>
                </Form>
                <br />

                <PageActions
                    primaryAction={{
                        content: 'Save',
                        loading: isSaving,
                        onAction() {
                            handleSubmit();
                        }
                    }}
                />
                {toastMarkup}
            </Frame>
        </Page>
    )

}

export default connect(null, null)(Create);
