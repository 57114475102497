import React, {useEffect, useState} from 'react';
import http from '../../utilities/http';
import { connect, useDispatch } from 'react-redux';
import {
    Card,
    Form,
    Frame,
    Layout,
    Page, PageActions,
    Select,
    SkeletonBodyText,
    Toast,
} from "@shopify/polaris";
import format from 'date-fns/format';
import { TitleBar } from "@shopify/app-bridge-react";
import { setSiteSettingsUpdated } from "../../store/actions/siteActions";

function Settings() {

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [changeCounter, setChangeCounter] = useState(0);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackIsError, setFeedbackIsError] = useState(false);
    const dispatch = useDispatch();

    const [settings, setSettings] = useState({
        postcode_matching: 'exact',
        currency: 'usd',
        date_format: 'MM/dd/yyyy'
    })

    useEffect(() => {

        http.get('/settings').then(function(response) {
            setIsLoading(false);
            setSettings(response.data);
        }).catch(err => {
            console.log('err', err);
        })
    }, []);

    const handleChange = (field, newValue) => {
        let formZone = settings;
        formZone[field] = newValue;
        setSettings(formZone);
        setChangeCounter(changeCounter+1);
    }

    const handleSubmit = () => {
        let data = settings;
        setIsSaving(true);
        setFeedbackIsError(false);

        http.post(`/settings`, data).then(response => {
            dispatch(setSiteSettingsUpdated(response.data));
        }).catch(err => {
            setFeedbackIsError(true);
        }).finally(() => {
            setIsSaving(false);
            setShowFeedback(true);
        });
    }

    const toastMarkup = showFeedback ? (
        <Toast content={(feedbackIsError ? "Error. Try again" : "Settings saved")} error={feedbackIsError} duration={3000} onDismiss={() => setShowFeedback(false)} />
    ) : null;

    const dateFormatFull = format(new Date(), 'MMM dd, yyyy');
    const dateFormatUSA = format(new Date(), 'MM/dd/yyyy');
    const dateFormatEU = format(new Date(), 'dd/MM/yyyy');

    return (
        <Page title="Settings">
            <TitleBar title="Settings" />
            <Frame>
                <Form>
                    <Layout>
                        <Layout.AnnotatedSection
                            title="General Settings"
                            description="Change your app settings"
                        >
                            <Card sectioned>
                                {isLoading && <SkeletonBodyText />}

                                {!isLoading && (
                                    <div>
                                        <Select
                                            label="Postcode Matching"
                                            options={[
                                                {
                                                    label: 'Exact match',
                                                    value: 'exact'
                                                },
                                                {
                                                    label: 'Partial match',
                                                    value: 'partial'
                                                }
                                            ]}
                                            onChange={(value) => handleChange('postcode_matching', value)}
                                            value={settings.postcode_matching}
                                        />

                                        <br />

                                        <Select
                                            label="Date Format"
                                            options={[
                                                {
                                                    label: dateFormatFull,
                                                    value: 'MMM dd, yyyy'
                                                },
                                                {
                                                    label: dateFormatEU,
                                                    value: 'dd/MM/yyyy'
                                                },
                                                {
                                                    label: dateFormatUSA,
                                                    value: 'MM/dd/yyyy'
                                                }
                                            ]}
                                            onChange={(value) => handleChange('date_format', value)}
                                            value={settings.date_format}
                                        />

                                        <br />

                                        <Select
                                            label="Currency"
                                            options={[
                                                {
                                                    label: 'US Dollar (USD)',
                                                    value: 'USD'
                                                },
                                                {
                                                    label: 'Australian Dollar (AUD)',
                                                    value: 'AUD'
                                                },
                                                {
                                                    label: 'EUR',
                                                    value: 'Euro (EUR)'
                                                },
                                                {
                                                    label: 'Canadian Dollar (CAD)',
                                                    value: 'CAD'
                                                }
                                            ]}
                                            onChange={(value) => handleChange('currency', value)}
                                            value={settings.currency}
                                            helpText="This should match your shopify store"
                                        />
                                    </div>
                                )}


                            </Card>
                        </Layout.AnnotatedSection>
                    </Layout>
                </Form>
                <br />
                <PageActions
                    primaryAction={{
                        content: 'Save',
                        loading: isSaving || isLoading,
                        onAction() {
                            handleSubmit();
                        }
                    }}
                />
                {toastMarkup}
            </Frame>

        </Page>
    )

}

export default connect(null, null)(Settings);
