import React, {useEffect, useState} from 'react';
import http from '../../utilities/http';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    Badge,
    Card,
    EmptyState, Filters,
    Layout,
    Page, Pagination,
    ResourceItem,
    ResourceList,
    Stack,
    TextStyle
} from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import useDebounce from "../../utilities/useDebounce";
import { setPage1Zones } from "../../store/actions/zoneActions";

function List() {

    const dispatch = useDispatch();
    const [queryValue, setQueryValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [zones, setZones] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const searchQuery = useDebounce(queryValue, 500);
    const initZones = useSelector(state => state.zone);


    useEffect(() => {
        setIsLoading(true);
        http.get('/zones?page=' + currentPage + '&search=' + searchQuery).then(function(response) {
            setIsLoading(false);
            setZones(response.data.items);
            setTotalPages(response.data.pager.totalPages);

            if (currentPage === 1 && searchQuery === '') {
                dispatch(setPage1Zones(response.data.items));
            }

        }).catch(err => {
            console.log('err', err);
        })
    }, [searchQuery, currentPage, dispatch]);



    useEffect(() => {
        setZones(initZones.zones ?? []);
    },[initZones.zones]);

    const emptyStateMarkup = zones.length === 0 ? (
        <EmptyState
            heading="Create a zone to get started"
            action={{content: 'Create Zone', url: '/zones/create'}}
            image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
        >
            <p>Add zones to define what post codes get what shipping rate</p>
        </EmptyState>
    ) : undefined;

    const shortenedPostcodes = (item) => {
        let postcodes = item.postcodes;

        if (postcodes.length > 100) {
            return postcodes.substr(0, 100) + '...';
        }

        return postcodes;
    }

    const filterControl = (
        <Filters
            queryPlaceholder="Filter zones by name or postcode"
            queryValue={queryValue}
            onQueryChange={value => {
                setQueryValue(value);
                setCurrentPage(1);
            }}
            onQueryClear={() => {
                setQueryValue('');
                setCurrentPage(1);
            }}
            filters={[]}
        >

        </Filters>
    );

    return (
        <Page title="Zones"
              primaryAction={{content: 'Add Zone', url: '/zones/create'}}
        >
            <TitleBar title="Zones" />
            <Layout>
                <Layout.AnnotatedSection
                    title="Zones"
                    description="Zones are a group of postcodes with the same pricing attached to them"
                >
                    <Card>
                        <ResourceList
                            resourceName={{singular: 'zone', plural: 'zones'}}
                            emptyState={emptyStateMarkup}
                            items={zones}
                            filterControl={filterControl}
                            loading={isLoading}
                            renderItem={(item) => {
                                return (
                                    <ResourceItem
                                        id={item.id}
                                        url={`/zones/${item.id}/edit`}
                                        name={item.name}
                                        accessibilityLabel={`View details for ${item.name}`}
                                        loading={isLoading}
                                    >
                                        <h3>
                                            <Stack>
                                                <Stack.Item fill>
                                                    <TextStyle variation="strong">{item.name}</TextStyle>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    {!item.is_active && (
                                                        <Badge>Disabled</Badge>
                                                    )}
                                                    {item.is_fallback && (
                                                        <Badge status="attention">Fallback</Badge>
                                                    )}
                                                </Stack.Item>
                                            </Stack>
                                        </h3>
                                        <TextStyle variation="subdued">
                                            {shortenedPostcodes(item)}
                                            {(item.postcodes === '' || item.postcodes === null) && <div>No postcodes entered.</div>}
                                        </TextStyle>
                                    </ResourceItem>
                                )
                            }}
                            />
                        <br />

                        {totalPages > 1 && (<div style={{textAlign: "center"}}>
                            <Pagination
                                hasPrevious={currentPage > 1}
                                onPrevious={() => {
                                    if (currentPage > 1) {
                                        setCurrentPage(currentPage - 1);
                                    }

                                }}
                                hasNext={currentPage < totalPages}
                                onNext={() => {
                                    setCurrentPage(currentPage + 1);
                                }}
                            />
                            <br /><br />
                        </div>)}
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>

        </Page>
    )

}

export default connect(null, null)(List);
