const initialState = {
    site: {},
    isLoaded: false,
    isSubscribed: false,
    isDevelopmentStore: true
};

const siteReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SITE_FETCHED_SUCCESS':
            return {
                ...state,
                site: action.site,
                isLoaded: true,
                isSubscribed: action.site.isSubscribed,
                isDevelopmentStore: action.site.isDevelopmentStore
            };
        case 'SITE_SETTINGS_UPDATED':
            return {
                ...state,
                site: {...state.site, dateFormat: action.date_format, postcodeMatching: action.postcode_matching}
            }
        case 'SITE_IS_SUBSCRIBED':
            return {
                ...state,
                isSubscribed: action.isSubscribed
            };
        case 'SITE_IS_INSTALLED':
            return {
                ...state,
                site: {...state.site, installed: action.installed}
            }
        default:
            return state;
    }
};

export default siteReducer;
