import React from 'react';
import { connect } from 'react-redux';
import {
    Stack, TextStyle
} from "@shopify/polaris";

function Rate(props) {
    const { rate } = props;

    const currency = (number) => {
        let total = number / 100;
        return total.toFixed(2);
    }

    return (
        <div>
            <h3>
                <Stack>
                    <Stack.Item fill>
                        <TextStyle variation="strong">{rate.name}</TextStyle>
                    </Stack.Item>

                </Stack>
            </h3>
            <Stack>
                <Stack.Item fill>
                    {rate.description != null && (<Stack>
                        <Stack.Item>
                            <TextStyle variation="subdued">{rate.description}</TextStyle>
                        </Stack.Item>
                    </Stack>)}
                    <Stack>
                        <Stack.Item>
                            {rate.type === 'weight' && <TextStyle variation="subdued">{rate.minimum} {rate.maximum == null ? ' and up' : `- ${rate.maximum}` }</TextStyle>}
                            {rate.type === 'item_count' && <TextStyle variation="subdued">{rate.minimum} {rate.maximum == null ? ' and up' : `- ${rate.maximum}` } item(s)</TextStyle>}
                            {rate.type === 'subtotal' && <TextStyle variation="subdued">${currency(rate.minimum)} {rate.maximum == null ? ' and up' : `- $${currency(rate.maximum)}` }</TextStyle>}
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <TextStyle variation="strong">${currency(rate.amount)}</TextStyle>
                </Stack.Item>
            </Stack>
        </div>
    )
}

export default connect(null, null)(Rate);
