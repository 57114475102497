import React, { useEffect, useState } from 'react';
import http from '../../../utilities/http';
import { connect } from 'react-redux';
import {
    Form,
    Page,
    Frame,
    Toast,
    PageActions,
    Layout, Card, Modal, TextContainer
} from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";
import { useHistory, useParams } from "react-router-dom";
import RateForm from "./components/RateForm";

function Edit() {


    const [isSaving, setIsSaving] = useState(false);
    const [rate, setRate] = useState({});
    const [newRate, setNewRate] = useState({});
    const [isLoading ,setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [changeCounter ,setChangeCounter] = useState(0);
    const [showFeedback, setShowFeedback] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    let history = useHistory();
    let { zoneId, id } = useParams();

    useEffect(() => {

        http.get(`/zones/${zoneId}/rates/${id}`).then(function(response) {
            const myRate = response.data;

            if (myRate.type === 'subtotal') {
                myRate.minimum = (myRate.minimum / 100).toFixed(2);
                myRate.maximum = (myRate.maximum !== null) ? (myRate.maximum / 100).toFixed(2) : '';
            } else {
                myRate.minimum = myRate.minimum.toString();
                myRate.maximum = (myRate.maximum == null) ? '' : myRate.maximum.toString();
            }

            myRate.amount = (myRate.amount / 100).toFixed(2);

            setIsLoading(false);
            setRate(myRate);
            setNewRate({...myRate});
        }).catch(err => {
            console.log('err', err);
        })
    }, [zoneId, id]);

    useEffect(() => {}, [newRate]);

    const handleSubmit = () => {
        let data = {...newRate};
        delete data.id;

        if (data.maximum === "") {
            delete data.maximum;
        } else {
            data.maximum = parseInt(data.maximum);
        }

        data.minimum = parseInt(data.minimum);
        data.amount = parseFloat(data.amount);

        setIsSaving(true);

        http.put(`/zones/${zoneId}/rates/${rate.id}`, data).then(response => {
            setIsSaving(false);
            setShowFeedback(true);
        }).catch(error => {
            setIsSaving(false);
            if (error.response.status === 422) {
                setFormErrors(error.response.data.errors);
            }
        });
    };

    const handleDelete = () => {
        setIsDeleting(true);
        http.delete(`/zones/${zoneId}/rates/${id}`).then(response => {
            history.replace(`/zones/${zoneId}/edit`);
        }).catch(error => {
            setIsDeleting(false);
        });
    }

    const handleChange = (field, newValue) => {
        let formZone = newRate;
        formZone[field] = newValue;
        setNewRate(formZone);
        setChangeCounter(changeCounter+1);
    }

    const toastMarkup = showFeedback ? (
        <Toast content="Rate saved" duration={3000} onDismiss={setShowFeedback} />
    ) : null;

    if (isLoading) return <div>Loading...</div>

    return (
        <Page
            title={`Edit Rate ${rate.name}`}
            breadcrumbs={[
                {
                    content: 'Zones',
                    url: `/zones/`
                },
                {
                    content: 'Back to Zone',
                    url: `/zones/${zoneId}/edit`
                }
            ]}
        >
            <TitleBar title={`Edit Rate ${rate.name}`} />
            <Frame>
                <Form onSubmit={handleSubmit}>
                    <Layout>
                        <Layout.AnnotatedSection
                            title="Rate Details"
                            description="General rate information"
                        >
                            <Card sectioned>
                                <RateForm rate={newRate} formErrors={formErrors} handleChange={handleChange} />
                            </Card>
                        </Layout.AnnotatedSection>
                    </Layout>
                </Form>
                <br />

                <PageActions
                    primaryAction={{
                        content: 'Save',
                        loading: isSaving,
                        onAction() {
                            handleSubmit();
                        }
                    }}
                    secondaryActions={[
                        {
                            content: 'Delete rate',
                            destructive: true,
                            onAction() {
                                setDeleteModalOpen(true);
                            }
                        },
                    ]}
                />
                {toastMarkup}

                <Modal

                    open={deleteModalOpen}
                    onClose={handleChange}
                    title="Delete Rate?"
                    primaryAction={{
                        content: 'Delete Rate',
                        destructive: true,
                        loading: isDeleting,
                        onAction() {
                            handleDelete()
                        }
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction() {
                                setDeleteModalOpen(false);
                            },
                        },
                    ]}
                >
                    <Modal.Section>
                        <TextContainer>
                            <p>Are you sure you want to delete the rate? This can't be undone.</p>
                        </TextContainer>
                    </Modal.Section>
                </Modal>
            </Frame>
        </Page>
    )
}

export default connect(null, null)(Edit);
