// import agents from '../../api/agents';

export const setCurrentSite = (site) => {
    return (dispatch) => {
        return dispatch({
            type: 'SITE_FETCHED_SUCCESS',
            site
        });
    }
}

export const setBillingSubscribedStatus = (isSubscribed) => {
    return (dispatch) => {
        return dispatch({
            type: 'SITE_IS_SUBSCRIBED',
            is_subscribed: isSubscribed
        });
    }
}

export const setCarrierServiceIsActive = (isActive) => {
    return (dispatch) => {
        return dispatch({
            type: 'SITE_IS_INSTALLED',
            installed: isActive
        });
    }
}

export const setSiteSettingsUpdated = (site) => {
    return (dispatch) => {
        return dispatch({
            type: 'SITE_SETTINGS_UPDATED',
            date_format: site.date_format,
            postcode_matching: site.postcode_matching
        })
    }
}
