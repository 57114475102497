const initialState = {
    zones: []
};

const zoneReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INITIAL_ZONES_LOADED':
            return {
                ...state,
                zones: action.zones
            };
        default:
            return state;
    }
};

export default zoneReducer;
