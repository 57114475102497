const initialState = {
    jwt: null,
    domain: null,
    isLoggedIn: true
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTH_LOGIN_SUCCESS':
            window.sessionStorage.setItem('jwt', action.access_token);
            return {
                ...state,
                isLoggedIn: true,
                jwt: action.access_token,
                domain: action.domain
            };
        case 'AUTH_LOGOUT':
            window.sessionStorage.removeItem('jwt');
            return {
                ...state,
                isLoggedIn: false,
                jwt: null,
                domain: null
            };
        default:
            return state;
    }
};

export default authReducer;
