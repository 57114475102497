import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import List from './List';
import Edit from './Edit';
import Create from './Create';
import Rates from './rates';

function Zones() {
    let { url } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${url}/create`} component={Create} />
                <Route path={`${url}/:id/edit`} component={Edit} />
                <Route path={`${url}/:zoneId/rates`} component={Rates} />
                <Route path={`${url}/`} component={List} />
            </Switch>
        </div>
    );
}

export default Zones;
