import React from 'react';
import { connect } from 'react-redux';
import { CalloutCard } from "@shopify/polaris";

function CarrierServiceRequired() {
    return (
        <CalloutCard
            title="Carrier Service API Not Enabled"
            primaryAction={{
                content: 'Shopify Help Site',
                url: 'https://help.shopify.com/en',
            }}
        >
            <p>
                In order to use Rates by Postcode, you must have access to the Shopify carrier service.
            </p>
            <p>
                Please contact Shopify to get that enabled.
            </p>
        </CalloutCard>
    )

}

export default connect(null, null)(CarrierServiceRequired);
