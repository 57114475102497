import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Edit from './Edit';
import Create from './Create';

function Zones() {
    let { path } = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${path}/create`} component={Create} />
                <Route path={`${path}/:id/edit`} component={Edit} />
            </Switch>
        </div>
    );
}

export default Zones;
